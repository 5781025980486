import urljoin from 'url-join';

const isJsScript = script => !script.type || script.type === 'text/javascript';
const isUnmountScript = script => script.type === 'ilc/unmount';

const wrapScript = (script, overrideRequire) => {
    script.remove();

    if (overrideRequire === undefined) {
        return '(function(){' + script.innerHTML + '})()';
    }

    return (
        '(function(){' +
            `System.overrideImportMap("@sharedLibrary/${overrideRequire.alias}", "${overrideRequire.path}");` +
            script.innerHTML +
        '})()'
    );
};

const sanitizeHtmlWithScripts = (html, overrideRequire) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    const { immediately, unmount } = Array.from(doc.querySelectorAll('script'))
    .reduce(
        (acc, item) => {
            if (isJsScript(item)) {
                acc.immediately.push(wrapScript(item, overrideRequire));
            } else if (isUnmountScript(item)) {
                acc.unmount.push(wrapScript(item, overrideRequire));
            }
            return acc;
        },
        {
            immediately: [],
            unmount: [],
        }
    );

    return {
        html: doc.body.innerHTML,
        runScripts: () => immediately.forEach(eval),
        unmountApp: unmount.length ? () => unmount.forEach(eval) : null,
    };
};

const bootstrap = function () { return Promise.resolve(); };

const mount = async (opts, props) => {
    let { htmlUrl: url, overrideRequire } = props.getCurrentPathProps();
    const { intl } = props.appSdk;
    const urlWithoutLocale = intl.parseUrl(window.location.pathname).cleanUrl;
    const subRoute = urlWithoutLocale.replace(props.getCurrentBasePath(), '');

    if (url.includes('{subRoute}')) {
        const [urlS, urlE] = url.split('{subRoute}');
        url = urljoin(urlS, subRoute, urlE);
    }

    try {
        const result = await fetch(url);
        const resultText = await result.text();

        const { html, runScripts, unmountApp } = sanitizeHtmlWithScripts(resultText, overrideRequire);
        opts.unmountApp = unmountApp;

        const domEl = props.domElementGetter();
        domEl.innerHTML = html;
        runScripts();

        let prevPathname = window.location.pathname;
        opts.routeHandler = () => {
            // shouldn't be rerendered on change locale
            const currentPathName = window.location.pathname;
            if (intl.parseUrl(prevPathname).cleanUrl === intl.parseUrl(currentPathName).cleanUrl) {
                return;
            }
            prevPathname = currentPathName;

            unmount(opts, props);
            mount(opts, props);
        };
        window.addEventListener('popstate', opts.routeHandler);
    } catch (error) {
        props.errorHandler(error);
    }
};

const unmount = async (opts, props) => {
    const domEl = props.domElementGetter();

    if (!domEl) {
        throw Error(
            `single-spa-html: domElementGetter did not return a valid dom element`
        );
    }

    if (opts.unmountApp) {
        opts.unmountApp();
    } else {
        domEl.innerHTML = '';
    }

    window.removeEventListener('popstate', opts.routeHandler);
};


export const mainSpa = () => {
    const opts = {};

    return {
        bootstrap: props => bootstrap(opts, props),
        mount: props => mount(opts, props),
        unmount: props => unmount(opts, props),
    };
};
